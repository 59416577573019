import { Box, List, ListItem } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const SubmitOrder = () => {
  const { idx } = useParams();
  const [product, setProduct] = useState();
  const getProduct = async () => {
    try {
      const res = await axios.get(
        `http://localhost:5001/api/productDetail/${idx}`
      );
      console.log(res.data.product);
      setProduct(res.data.product);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);
  return (
    <Box>
      <Box>
        <img width={"200px"} src={product?.imgUrl} />
      </Box>
      <Box>상품명 {product?.name}</Box>
      <Box>가격 {product?.price}</Box>
      <Box>
        <List>
          {JSON.parse(product?.optionList).map((item) => {
            return <ListItem>{item}</ListItem>;
          })}
        </List>
      </Box>
    </Box>
  );
};

export default SubmitOrder;
