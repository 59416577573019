import {
  Box,
  Button,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

const ProductPage = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [uploadImgUrl, setUploadImgUrl] = useState("");
  const [option, setOption] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const imageUpload = async (e) => {
    const { files } = e.target;
    const uploadFile = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(uploadFile);
    reader.onloadend = () => {
      setUploadImgUrl(reader.result);
    };
    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    try {
      const response = await axios.post(
        `http://localhost:5001/api/img`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      setImgUrl(response.data.url);
    } catch (error) {
      console.log(error);
    }
  };
  const saveProduct = async () => {
    const data = {
      name: name,
      imgUrl: imgUrl,
      price: price,
      optionList: JSON.stringify(optionList),
    };
    try {
      const response = await axios.post(
        `http://localhost:5001/api/product`,
        data
      );
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100vw"}
      mt={10}
      gap={2}
    >
      <Typography variant="h4">상품등록하기</Typography>
      <TextField
        size="small"
        label="상품이름"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <img width={"90vw"} src={uploadImgUrl} img="img" />
      <input
        type="file"
        accept="image/*"
        name="image"
        onChange={(e) => imageUpload(e)}
      />
      <TextField
        size="small"
        label="가격"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />
      <Box>
        <TextField
          value={option}
          onChange={(e) => setOption(e.target.value)}
          label="옵션 추가"
          size="small"
        />
        <Button
          onClick={() => {
            setOptionList([...optionList, option]);
            setOption("");
          }}
        >
          옵션추가
        </Button>
      </Box>
      <Box>
        <List>
          {optionList.map((item) => {
            return <ListItem>{item}</ListItem>;
          })}
        </List>
      </Box>
      <Box>
        <Button onClick={saveProduct}>저장</Button>
        <Button color="error">취소</Button>
      </Box>
    </Box>
  );
};

export default ProductPage;
