import { Box, List, ListItem, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const OrderPage = () => {
  const [productList, setProductList] = useState([]);
  const navi = useNavigate();
  const getProduct = async () => {
    try {
      const response = await axios.get(`http://localhost:5001/api/product`);
      console.log(response.data);
      setProductList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProduct();
  }, []);
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant="h4">주문하기</Typography>
        <Box>
          <List>
            {productList.map((item) => {
              return (
                <ListItem onClick={() => navi(`/order/${item.idx}`)}>
                  <Box
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    width={"300px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={1}
                  >
                    <img width={"50%"} src={item.imgUrl} />
                    <Box>
                      <Typography>상품명 {item.name}</Typography>
                      <Typography>가격 {item.name}</Typography>
                    </Box>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderPage;
